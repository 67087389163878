<template>
    <div class="TeamLedgerList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="stationToolFram" ref="stationToolFram">
                    <el-form-item label="条码">
                        <el-input v-model="stationToolFram.barcode" placeholder="请输入条码查询"></el-input>
                    </el-form-item>
                    <el-form-item label="工器具名称">
                        <el-input v-model="stationToolFram.toolName" placeholder="请输入工器具名称查询"></el-input>
                    </el-form-item>
                    <el-form-item label="工器具分类">
                        <el-select placeholder="请选择工器具分类名称" v-model="stationToolFram.classCode">
                            <el-option v-for="(item, index) in toolClassList" :key="index" :label="item.className"
                                :value="item.classCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="工器具编号">
                        <el-input v-model="stationToolFram.toolNum" placeholder="请输入工器具编号查询"></el-input>
                    </el-form-item> -->
                    <el-form-item label="状态">
                        <!-- <el-input v-model="formInline.account" placeholder="请输入账号查询"></el-input> -->
                        <el-select placeholder="请选择工器具状态" v-model="stationToolFram.status">
                            <el-option v-for="toolStatus in toolStatusList" :key="toolStatus.pKey"
                                :label="toolStatus.pValue" :value="toolStatus.pKey"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <el-button  class="btn" @click="$router.push({ name: 'station-ledger-create' })"
                        icon="el-icon-circle-plus-outline">新增</el-button>
                    <el-button type="primary" @click="handleDown" icon="el-icon-upload2">导出</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="stationToolList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="className" label="工器具分类" align="center"> </el-table-column>
                <el-table-column prop="toolName" label="工器具名称" align="center">
                </el-table-column>
                <el-table-column prop="toolModel" label="工器具规格型号" align="center">
                </el-table-column>
                <el-table-column prop="voltageGrade" label="电压等级" align="center">
                </el-table-column>
                <el-table-column prop="toolNum" label="工器具编号" align="center">
                </el-table-column>
                <el-table-column prop="whCodeName" label="所在库房" align="center">
                </el-table-column>
                <el-table-column prop="location" label="存放位置" align="center">
                </el-table-column>
                <el-table-column prop="indate" label="保质期/有效期（年）" align="center">
                </el-table-column>
                <el-table-column prop="productionDate" label="出厂日期" align="center">
                </el-table-column>
                <el-table-column prop="lastTestDate" label="上次试验日期" align="center">
                </el-table-column>
                <el-table-column prop="nextTestDate" label="下次试验日期" align="center">
                </el-table-column>
                <el-table-column prop="deployNorm" label="配置标准" align="center">
                </el-table-column>
                <el-table-column prop="nowDeploy" label="现有配置" align="center">
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                        <el-button type="text" size="mini" title="报废" class="delColor"
                            @click="del(scope.row)">报废</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="stationToolFram.pageNum" :page-sizes="[5, 10, 20, 50, 100]"
                :page-size="stationToolFram.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>
<script>

import { getToolClassAll } from '@/api/toolClass.js'
import { searchStationInfo, getToolStatus, scrapStationInfo } from '@/api/stationLedger.js'
import { downloadExcel } from '@/api/download.js'
export default {
    data() {
        return {
            stationToolList: [],
            toolClassList: [],
            toolStatusList: [],
            stationToolFram: {
                barcode: null,
                toolName: null,
                classCode: null,
                toolNum: null,
                status: null,
                pageNum: 1,
                pageSize: 10,
            },
            total: 0,

        }
    },
    mounted() {
        this.loadToolStatus()
        this.loadToolClass()
        this.loadTeamToolInfo()
    },
    methods: {

        handleSizeChange(val) {
            this.stationToolFram.pageSize = val;
            this.stationToolFram.pageNum = 1;
            this.loadTeamToolInfo();
        },
        handleCurrentChange(val) {
            this.stationToolFram.pageNum = val;
            this.loadTeamToolInfo();
        },

        async loadToolClass() {
            await getToolClassAll().then(res => {
                if (res.code === '000000') {
                    this.toolClassList = res.t
                }
            })
        },
        async loadToolStatus() {
            await getToolStatus().then(res => {
                if (res.code === '000000') {
                    this.toolStatusList = res.data
                }
            })
        },

        loadTeamToolInfo() {
            searchStationInfo(this.stationToolFram).then(res => {
                if (res.code === '000000') {
                    this.stationToolList = res.t.list
                    this.total = res.t.total
                    var count = 1;
                    this.stationToolList.forEach((item) => {
                        item.seq = count++

                    })
                }
            })
        },

        handleEdit({ id }) {
            this.$router.push({
                name: 'station-ledger-edit',
                params: {
                    id
                }
            })
        },
        handleDetail({ id }) {
            this.$router.push({
                name: 'station-ledger-detail',
                params: {
                    id
                }
            })
        },
        //报废
        del({id,toolName}) {
            this.$confirm('是否报废名称为“'+toolName+'”的变电站安全工器具？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                scrapStationInfo(id).then(res => {
                    if (res.code === '000000') {
                        this.loadTeamToolInfo()
                        this.$message.success('报废成功')
                        // this.loadSelect()
                       
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })

        },

        //搜索
        handleSearch() {
            this.loadTeamToolInfo(this.stationToolFram);
        },
        //重置
        handleReset() {
            this.stationToolFram = {};
            this.loadTeamToolInfo(this.stationToolFram);
        },

        //导出
        handleDown() {
            this.$fullScreenLoading.show("导出中...");
            
            downloadExcel(this.stationToolFram,'/stationInfo/downloadExcel').then(res => {
                setTimeout(() => {
                    let blob = new Blob([res.data], {
                        type: "application/octet-stream",
                    });
                    // 创建一个a标签
                    var tempLink = document.createElement("a");
                    const fileNames = res.headers['content-disposition']
                    console.log(fileNames)
                    const regFileNames = fileNames.match(/=(.*)$/)[1]
                    console.log(regFileNames)
                    // let filename = "自定义文件名称"+ '.xls'
                    // 将blob对象转为一个URL
                    var blobURL = window.URL.createObjectURL(blob);

                    // 隐藏a标签
                    tempLink.style.display = "none";
                    // 设置a标签的href属性为blob对象转化的URL
                    tempLink.href = blobURL;
                    tempLink.download = decodeURI(regFileNames)// 下载后文件名
                    // 将a标签添加到body当中
                    document.body.appendChild(tempLink);
                    // 启动下载
                    tempLink.click();
                    // 下载完毕删除a标签
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                    this.$fullScreenLoading.hide();
                    this.$message({
                    message: "导出成功~",
                    type: "success",
                });
                }, 500)
                

            })
        },

    }
}
</script>

<style lang="scss" scoped>
.TeamLedgerList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

}
</style>